.sharePUP {
    position: absolute;
    display: block;

    top:0px;
    width: 100%;

    text-align: center;
    
    font-size:112.5%;
    font-weight:400;
    font-family:birra-body,sans-serif;
    line-height:2.2;
    color:#060000;
/*    background:url(images/background/bgpaper.png) repeat 50% #fef9e0; */
    background-color:rgba(0,0,0,0.2);
    
    padding:17px;

    z-index: 100000;
}
.sharePUP .inner {
    position: relative;
    top: 0px;
    height:200px;

    padding:0px;
    margin:0;
    width: calc(100% - 34px);

    border-radius: 15px;
    background-color: #fff9e0;
}
.sharePUP .inner .btn-dorshare:first-child{
    margin-top:30px;
}
.sharePUP .inner .btn-dorshare {
/*    height: 60px; */

    margin-left:0px;
    margin-top:10px;
    margin-bottom:10px;

    text-indent: 0px; 
    text-align: center;
/*    line-height: 30px; */
}
.sharePUP .inner .btn-dorshare svg {
/*
    position: absolute;
    right:10px;
    top:20px;
*/
}