.page {
  width:100%;
  height:100%;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    
    pointer-events: none;
}
h2 {
  font-size: 1.8rem;
  font-family: birra-title, sans-serif;
  line-height:1.0;
  text-transform: uppercase;
  color: #772935;
}

h3 {
  font-size: 1.0rem;
  font-family:birra-body,sans-serif;
  line-height:1.2;
  color: #4a3024;
  text-align: center;
}

/* start page */
.bg-img-blur,
.bg-img-blur-2 {
  position: absolute;
  display:block;
  left:0px;
  top:0px;
  width:100%;
  height:auto;
  overflow: hidden;
  z-index: -1;
}
.bg-img-blur-2 {
  z-index:0;
  overflow: auto;
}
.bg-img-blur .inner,
.bg-img-blur-2 .inner {
  margin-top:-100px;
}

.start-top {
  width:100%;
  z-index: 10;
  height:auto;
}
.start-top:after {
  clear: both;
  content: "";
  display: table;
}
.start-top .speechbubble1 {
  position: absolute;
  display: none;
  left:5vw;
  top:50px;
  width:63vw;
  height:63vw;
  background:url(gfx/bubble-start-1.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index:2;
}
.start-top .speechbubble1 .text,
.start-top .speechbubble1 .textinv {
  position: absolute;
  top:15%;
  left:4vw;

  transform: rotate(-8deg);
  transform-origin: top left;

  font-family: birra-body;
  font-size:6vw;
  line-height: 6vw;
  color:#fff9e0;

  text-transform: uppercase;
}
.start-top .speechbubble1 .textinv {
  position: relative;
}
.start-top .speechbubble1 .text .bigger {
  font-size:7vw;
  line-height: 10vw;
}

.start-top .start-man-right {
  position: relative;
  margin-top:100px;
  width:80%;
  float:right;
  z-index: 10;
}
.start-top .man-right {
  background:url(gfx/start-man.webp) no-repeat;
}

.start-mid {
  width:100%;
  height:auto;
  border-top-left-radius: 25px;
  background-color:#fff9e0;
}
.start-mid .start-panel {
  display: block;
  margin-top:-100px;
  width: 100%;
  height:auto;

  font-family: birra-body;
  font-size: 7vw;
  line-height: 7vw;
  font-weight: normal;

  z-index: 3;
}
.start-mid .start-panel .text {
  position: absolute;
  display: block;
  margin-top:80px;
  padding-left:11%;
  width:80%;
  height:auto;
  text-transform:uppercase;
  
  color:#4f2c1d;
}
.start-mid .start-panel .text:after {
  clear: both;
  content: "";
  display: table;
}

.start-mid .start-panel .btnstartwrapper {
  position: relative;
  display: block;
  width:100%;
  text-align: center;
  z-index: 3;
}
.start-mid .start-panel .btnstartwrapper .btnstarthome,
.start-mid .start-panel .btnstartwrapper .btnstarthome-over {
  display: inline-block;
  border-radius: 15px;
  border: 2px solid #ab2328;
  width: calc(100% - 22%);
  height: 50px;
  
  text-align: center;
  font-family: ChunkFive;
  font-size: 25px;
  line-height: 50px;
  font-weight: normal;
  text-decoration: none;

  background-color: #ab2328;
  color: #fff9e0;
}

.dropshadow-bottom {
  filter: drop-shadow(0 5px 0.3rem rgba(0,0,0,0.2));
}

/*

  take picture

*/
.speechbubble2 {
  position: absolute;
  display: none;
  left:50vw;
  top:10px;
  width:43vw;
  height:43vw;
  background:url(gfx/bubble-takepicture-1.webp);
  background-size: contain;
  background-repeat: no-repeat;
  z-index:20;
}
.speechbubble2 .text {
  position: absolute;
  top:6%;
  left: 6.6vw;

  transform: rotate(4.8deg);
  transform-origin: top left;

  font-size:7.5vw;
  line-height: 6vw;
  color:#fff9e0;

  text-transform: uppercase;
}

.btnTP {
	position: relative;
	display: block;
	z-index: 20;
	margin:0;
	padding:0;
	width:20%;
	height:auto;
	left:50%;
	margin-left:-10%;
}
.btnTP::after {
	clear: both;
	content: "";
	display: table;
}
.txtTakeFoto {
	position: relative;
	display: block;

	text-align: center;
	font-size:8vw;
	line-height:8vw;
	color:#4f2c1d;

	margin-top:0px;
	margin-bottom:10px;

	z-index:10;
}
.txtTakeFoto::after {
	clear: both;
	content: "";
	display: table;
}

/*

  skintone

*/
.speechbubble3 {
  position: absolute;
  display: none;
  left:35vw;
  top:300px;
  width:43vw;
  height:43vw;
  background:url(gfx/bubble-skintone.webp);
  background-size: contain;
  background-repeat: no-repeat;
  z-index:20;
}
.speechbubble3 .text {
  position: absolute;
  top:25%;
  left:8.0vw;

  text-align: center;
  transform: rotate(9.0deg);
  transform-origin: top left;

  font-size:7.5vw;
  line-height: 6vw;
  color:#fff9e0;

  text-transform: uppercase;
}

.txtSkintone {
	position: relative;
	display: block;

	text-align: center;
	font-size:8vw;
	line-height:8vw;
	color:#4f2c1d;

	margin-top:0px;
	margin-bottom:10px;

	z-index:10;
}
.txtSkintone::after {
	clear: both;
	content: "";
	display: table;
}

.skintone-mid {
  position: relative;
  display: block;
  width:100%;
  height:50px;
  margin-bottom: 30px;
  z-index: 20;
}
.skintone-mid .btnstwrapper {
  display: flex;
  width:calc(100% - 22%);
  margin-left:11%;
  z-index: 30;
}
.skintone-mid .btnstwrapper .btnprev,
.skintone-mid .btnstwrapper .btnprev-over {
  position: relative;
  display: inline-block;
  border-radius: 15px;
  border: 2px solid #4f2c1d;
  width: 44px;
  height: 50px;
  
  text-align: center;
  font-size: 25px;
  line-height: 46px;
  text-decoration: none;

  margin-right:5%;

  background-color: #4f2c1d;
  color: #fff9e0;
}
.skintone-mid .btnstwrapper .btnprev svg,
.skintone-mid .btnstwrapper .btnprev-over svg{
  pointer-events: none;
  position: absolute;
  left:10px;
  top:15px;
  width:24px;
}
.skintone-mid .btnstwrapper .btnnext,
.skintone-mid .btnstwrapper .btnnext-over {
  display: inline-block;
  border-radius: 15px;
  border: 2px solid #ab2328;
  width: 80%;
  height: 50px;

  text-align: center;
  font-family: ChunkFive;
  font-size: 25px;
  line-height: 50px;
  font-weight: normal;
  text-decoration: none;

  background-color: #ab2328;
  color: #fff9e0;
}

/*

  edit picture

*/
.speechbubbleEP {
  position: absolute;
  display: none;
  left:50vw;
  top:2px;
  width:43vw;
  height:43vw;
  background:url(gfx/bubble-takepicture-1.webp);
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index:3;
}
.speechbubbleEP .text {
  position: absolute;
  top:10%;
  left: 6.6vw;

  transform: rotate(4.0deg);
  transform-origin: top left;

  font-size:7.5vw;
  line-height: 6vw;
  color:#fff9e0;

  text-transform: uppercase;
}

/*

  name input

*/
.bg-img-bottle {
  position: absolute;
  display:block;
  left:0px;
  top:0px;
  width:100%;
  height:auto;
  overflow: hidden;
  z-index: -1;
}
.bg-img-bottle .inner {
  margin-top:-100px;
}

.speechbubbleBad {
  position: absolute;
  display: none;
  left:0vw;
  top:9vh;
  width:99vw;
  height:99vw;
  background:url(gfx/keep-it-friendly-bg.webp);
  background-size: contain;
  background-repeat: no-repeat;
  z-index:20;
}
.speechbubbleBad .text {
  position: absolute;
  top:13%;
  left:9vw;

  text-align:left;
  transform: rotate(-4.0deg);
  transform-origin: top left;

  font-size:11.7vw;
  line-height: 13.0vw;
  font-weight: bold;
  color:#fff9e0;

  text-transform: uppercase;
}

.txtYourName{ 
	position: relative;
	display: block;

	text-align: center;
	font-size:8vw;
	line-height:8vw;
	color:#4f2c1d;
  
	margin-top:0px;
	margin-bottom:0px;

	z-index:10;
}
.txtMaxSymbols {
	position: relative;
	display: block;

	text-align: center;
	font-size:3.5vw;
	line-height:4vw;
	color:#4f2c1d;
  
  font-family:gothambook;
	margin-top:0px;
	margin-bottom:10px;

	z-index:10;
}

/*

  edit birthdate

*/

.txtYourBirthDate {
	position: relative;
	display: block;

	text-align: center;
	font-size:8vw;
	line-height:8vw;
	color:#4f2c1d;
  
	margin-top:0px;
	margin-bottom:10px;

	z-index:10;
}

/*

  stories

*/
.speechbubbleLG {
  position: absolute;
  display: none;
  left:-4.5vw;
  top:284px;
  width:56vw;
  height:56vw;
  background:url(gfx/bubble-look-amazing.webp);
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index:110;
}
.speechbubbleLG .text {
  position: absolute;
  top:15%;
  left:10.6vw;
  
  transform: rotate(-4.0deg);
  transform-origin: top left;

  font-size:9.5vw;
  line-height: 8vw;
  color:#fff9e0;

  text-transform: uppercase;
}
.page .btnstartwrapper {
  position: relative;
  display: block;
  width:100%;
  text-align: center;
  z-index: 3;
}
.page .btnstartwrapper .btnstarthome,
.page .btnstartwrapper .btnstarthome-over {
  display: inline-block;
  border-radius: 15px;
  border: 2px solid #ab2328;
  width: calc(100% - 22%);
  height: 50px;
  
  text-align: center;
  font-family: ChunkFive;
  font-size: 25px;
  line-height: 50px;
  font-weight: normal;
  text-decoration: none;
  
  background-color: #ab2328;
  color: #fff9e0;
}

/*

  waiting animation

*/
.speechbubbleWA {
  position: absolute;
  display: none;
  left:0px;
  top:0px;
  width:43vw;
  height:43vw;
  background:url(gfx/bubble-one-moment-please.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index:20;
}
.speechbubbleWA .text {
  position: absolute;
  top:27%;
  left:3vw;

  text-align:left;
  transform: rotate(-5.0deg);
  transform-origin: top left;

  font-size:7.0vw;
  line-height: 6.0vw;
  color:#fff9e0;

  text-transform: uppercase;
}

/*

  loading animation

*/
.speechbubbleLA {
  position: absolute;
  display: none;
  left:20vw;
  top:69vh;
  width:43vw;
  height:43vw;
  background:url(gfx/bubble-bottle-loading.webp);
  background-size: contain;
  background-repeat: no-repeat;
  z-index:20;
}
.speechbubbleLA .text {
  position: absolute;
  top:27%;
  left:3vw;

  text-align:left;
  transform: rotate(-5.0deg);
  transform-origin: top left;

  font-size:5.9vw;
  line-height: 6.0vw;
  color:#fff9e0;

  text-transform: uppercase;
}

/*

  instruction videos

*/
.intructvid-bg {
  position: absolute;
  display: block;
  width:100%;
  height:100%;
  background:url(gfx/instruct-bg.jpg);
  background-size:cover;
  background-repeat: no-repeat;
}
.video-bg {
  position: absolute;
  display: block;
  left:0px;
  top:0px;
  width:100%;
  z-index:1
}
.video-bg-tile {
  position: absolute;
  display: block;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  z-index:0;

  background:url(gfx/instruct-bg-stone-3.jpg);
  background-size: contain;
  background-repeat: repeat;
}

/*
  
  server upload error

*/
.speechbubbleSrvErr {
  position: absolute;
  display: none;
  left:0vw;
  top:9vh;
  width:99vw;
  height:99vw;
  background:url(gfx/keep-it-friendly-bg.webp);
  background-size: contain;
  background-repeat: no-repeat;
  z-index:20;
}
.speechbubbleSrvErr .text {
  position: absolute;
  top:13%;
  left:9vw;

  text-align:left;
  transform: rotate(-4.0deg);
  transform-origin: top left;

  font-size:11.7vw;
  line-height: 13.0vw;
  font-weight: bold;
  color:#fff9e0;

  text-transform: uppercase;
}

