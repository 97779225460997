.wsanim {
position: fixed;
display: table-row;
left:0px;
top:0px;

width: 100%;
height: 100%;
z-index: 2000;
text-align: center;

font-size:112.5%;
font-weight:400;
font-family:birra-body,sans-serif;
line-height:2.2;
color:#060000;
/*    background:url(images/background/bgpaper.png) repeat 50% #fef9e0; */
background-color:#fff9e0;

background-size:50%
}

.wsanim .canvaswrap {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  
  z-index: 1;
}
