.pl-page {
    position: fixed;
    display: table-row;

    width: 100%;
    height: 100%;
    z-index: 2000;
    text-align: center;
    
    font-size:112.5%;
    font-weight:400;
    font-family:birra-body,sans-serif;
    line-height:2.2;
    color:#060000;
/*    background:url(images/background/bgpaper.png) repeat 50% #fef9e0; */
    background-color:#fff9e0;
    
    background-size:50%
}
.pl-page .pre-loader {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pl-page .pre-loader .wrapper {
    position: relative;
    left: 50%;
    margin-left:-83px;
    width:166px;
    height:307px;
    height:10px;
}
.pl-page .pre-loader .loading-perc {
    position: relative;
    font-size: 17vw;
    color: #000;
    
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    z-index: 20;
}
