.panelUnder {
    position: absolute;
    display: block;
    left:0px;
    top:50%;
    width:100%;
    height:50px;
    border-top-left-radius: 25px;
    background-color:#fff9e0;
    z-index: 1;
  }
  