.olUS {
    width:100%;
    position:fixed;
    top:0;
    left:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;

    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    background-color:#fff9e0;
    height:100vh;
    padding-left:1rem;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    
    z-index:1000;
}
.olUS .anim {

}

.olUSError {
    width:100%;
    position:fixed;
    display:block;
    height:100vh;
    top:0;
    left:0;

    align-items:center;
/*    background-color:#fff9e0; */
    
    text-align: center;

    font-size: 5vw;
    line-height: 6vw;

    z-index:1000;
}
.olUSError .title {

    font-family: ChunkFive;
    font-size: 20vw;
    line-height: 22vw;
    font-weight: normal;
    text-decoration: none;
  
    color:#ab2328;
}
.olUSError .btnstwrapper {
    position: relative;
    display: block;
    width:100%;
} 
.olUSError .btnstwrapper .btnnext,
.olUSError .btnstwrapper .btnnext-over {
  display: inline-block;
  border-radius: 15px;
  border: 2px solid #ab2328;
  width: 80%;
  height: 50px;

  text-align: center;
  font-family: ChunkFive;
  font-size: 25px;
  line-height: 50px;
  font-weight: normal;
  text-decoration: none;

  background-color: #ab2328;
  color: #fff9e0;
}
