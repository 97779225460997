.cwrapcam {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  
  z-index: 1;
}
.not-allowed {cursor: not-allowed;}

.cwraplabel {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 100%;
  
  z-index: 1;
}
.canvasstories {
  position: absolute;
  display: block;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  
  z-index: 1;
}
.canvasstories-2 {
  position: absolute;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  
  z-index: 1;
}
