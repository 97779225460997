.olSpriteAnim {
    width:100%;
    position:fixed;
    top:0;
    left:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;

    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    background-color:#fff9e0;
    height:100vh;
    padding-left:1rem;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    
    opacity:1;

    z-index:2000;
}
