.tstepwrap {
    position: relative;
    width: 100%;
    height: 48px;
/*    background-color:rgba(255,0,0,0.2); */
}
.tstepwrap .totalstep {
    position: absolute;
    display: block;

    top:0px;
    left:30px;
    
    width: 100%;
    width:calc(100% - 60px);
    height: 20px;

    z-index: 20;
}
.tstepwrap .totalstep .linebase {
    position: absolute;
    top: 50%;
    height:2px;
    background-color: #e9d089;
    width:100%;
    z-index: 0;
}
.tstepwrap .totalstep .line {
    position: absolute;
    top: 47%;
    height:3px;
    background-color: #bd8100;
    width:0%;
    z-index: 1;
}
.tstepwrap .totalstep .bulletwrapper {
    position: absolute;
    left:0px;
    top:0px;
    width:24px;
    height:24px;

    opacity:0.5;
    opacity:1;

    z-index: 2;
}
.tstepwrap .totalstep  .bulletwrapper .bulletouterbase {
    position: absolute;
    left:0px;
    top:5px;
    width:11px;
    height:11px;
    
    border-radius: 50%;
    background-color: #e9d089;

    z-index: 1;
}
.tstepwrap .totalstep  .bulletwrapper .bulletouter {
    position: absolute;
    border-radius: 50%;
    background-color: #bd8100;
    
    display: none;

    width:22px;
    height:22px;

    z-index: 2;
}
.tstepwrap .totalstep  .bulletwrapper .bulletouter .checkmark {
    position: absolute;
    display: block;
    left:5px;
    top:-8px;
    width:12px;
    height:12px;
}
.tstepwrap .totalstep  .bulletwrapper .bulletouter .inner {
    position: absolute;
    display: block;
    left:7px;
    top:7px;
    width:8px;
    height:8px;
    
    border-radius: 50%;
    background-color: #fff9dd;

    z-index: 1;
}
.tstepwrap .totalstep .bulletwrapper .text {
    position:absolute;
    width:80px;
    text-align: center;
    top:16px;
    left:-40px;

    font-size: 18px;
    font-weight: 700;
    color:#4c1f0e;

/*    background-color: rgba(255,0,0,0.4); */
}