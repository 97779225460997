.cselector {
    position: absolute;
    display: block;
    top:100px;
    right:0px;
    width:80px;
    height:100%;
    z-index: 15;

    overflow: hidden;
}

.cselector a {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

}
.cselector .btn {
    position: relative;
    display: block;
    width:80px;
    height:40px;
    left:30px;

    margin-bottom:10px;

    filter: drop-shadow(0 0 0.3rem rgba(0,0,0,0.2));

}

.cselector .tip1,
.cselector .tip2,
.cselector .tip3,
.cselector .tip4,
.cselector .tip5,
.cselector .tip6 {
    position: absolute;
    display: block;
    width:40px;
    height:40px;
    border-color: #fcfaee;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-right:none;
    background-color: #fff8d4;
}


.cselector .tail1,
.cselector .tail2,
.cselector .tail3,
.cselector .tail4,
.cselector .tail5,
.cselector .tail6 {
    position: absolute;
    display: block;
    left:40px;
    top:0px;
    width:80px;
    height:40px;

    border-color: #fcfaee;
    border-width: 1px;
    border-style: solid;
    border-right:none;
    border-left:none;
    background-color: #fff8d4;
}

.cselector .tip2,
.cselector .tail2 {
    border-color: #fef8d4;
    background-color: #f0d3c5;
}
.cselector .tip3,
.cselector .tail3 {
    border-color: #bd8100;
    background-color: #e3b28d;
}
.cselector .tip4,
.cselector .tail4 {
    border-color: #fdefc7;
    background-color: #bc8d57;
}
.cselector .tip5,
.cselector .tail5 {
    border-color: #f7f3d0;
    background-color: #7d4327;
}
.cselector .tip6,
.cselector .tail6 {
    border-color: #f7f3d0;
    background-color: #351d01;
}
